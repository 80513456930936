'use client'

import { FC, useEffect, useState } from 'react'

import { WagmiProvider } from 'wagmi'

import { config, projectId } from '@/wagmi'
import { ThemeProvider } from '../theme-provider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { TorexRegistryProvider } from './TorexRegistryProvider'
import flagsmith from 'flagsmith/isomorphic'
import { FlagsmithProvider } from 'flagsmith/react'
import { IState } from 'flagsmith/types'
import { TorexRegistry } from '@/types'

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { PricesProvider } from './PricesProvider'

const queryClient = new QueryClient()

type ProviderProps = {
  children: React.ReactNode
  serverProps: {
    torexRegistry: TorexRegistry
    flagsmithState: IState<string, string>
  }
}

if (!projectId) throw new Error('Project ID is not defined')

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  allowUnsupportedChain: true,
  privacyPolicyUrl:`/privacy`,
  termsConditionsUrl: `/terms`
})

export const Providers: FC<ProviderProps> = ({
  children,
  serverProps: { torexRegistry, flagsmithState }
}) => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])

  return (
    <TorexRegistryProvider torexRegistry={torexRegistry}>
      <ThemeProvider
        attribute='class'
        defaultTheme='dark'
        disableTransitionOnChange
      >
        <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}>
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <PricesProvider>{mounted && children}</PricesProvider>
            </QueryClientProvider>
          </WagmiProvider>
        </FlagsmithProvider>
      </ThemeProvider>
    </TorexRegistryProvider>
  )
}

import(/* webpackMode: "eager" */ "/vercel/path0/apps/frontend/public/background.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/frontend/public/bg-ns.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/frontend/public/bg-nwse.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/frontend/public/bg-swne.svg");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/apps/frontend/src/components/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/apps/frontend/src/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/apps/frontend/src/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/frontend/src/components/providers/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lng]/layout.tsx\",\"import\":\"Work_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"workSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["WagmiContext","WagmiProvider"] */ "/vercel/path0/node_modules/wagmi/dist/esm/context.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAccount"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useAccount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAccountEffect"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useAccountEffect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBalance"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useBalance.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBlock"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useBlock.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBlockNumber"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useBlockNumber.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBlockTransactionCount"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useBlockTransactionCount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBytecode"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useBytecode.js");
;
import(/* webpackMode: "eager", webpackExports: ["useCall"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useCall.js");
;
import(/* webpackMode: "eager", webpackExports: ["useChainId"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useChainId.js");
;
import(/* webpackMode: "eager", webpackExports: ["useChains"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useChains.js");
;
import(/* webpackMode: "eager", webpackExports: ["useClient"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConfig"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useConfig.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnect"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useConnect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnections"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useConnections.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnectorClient"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useConnectorClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnectors"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useConnectors.js");
;
import(/* webpackMode: "eager", webpackExports: ["useDeployContract"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useDeployContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["useDisconnect"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useDisconnect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsAddress"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useEnsAddress.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsAvatar"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useEnsAvatar.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsName"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useEnsName.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsResolver"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useEnsResolver.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsText"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useEnsText.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEstimateFeesPerGas"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useEstimateFeesPerGas.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEstimateGas"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useEstimateGas.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEstimateMaxPriorityFeePerGas"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useEstimateMaxPriorityFeePerGas.js");
;
import(/* webpackMode: "eager", webpackExports: ["useFeeHistory"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useFeeHistory.js");
;
import(/* webpackMode: "eager", webpackExports: ["useGasPrice"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useGasPrice.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteReadContracts"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useInfiniteReadContracts.js");
;
import(/* webpackMode: "eager", webpackExports: ["usePrepareTransactionRequest"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/usePrepareTransactionRequest.js");
;
import(/* webpackMode: "eager", webpackExports: ["useProof"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useProof.js");
;
import(/* webpackMode: "eager", webpackExports: ["usePublicClient"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/usePublicClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReadContract"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useReadContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReadContracts"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useReadContracts.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReconnect"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useReconnect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSendTransaction"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useSendTransaction.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSignMessage"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useSignMessage.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSignTypedData"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useSignTypedData.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSimulateContract"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useSimulateContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["useStorageAt"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useStorageAt.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSwitchAccount"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useSwitchAccount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSwitchChain"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useSwitchChain.js");
;
import(/* webpackMode: "eager", webpackExports: ["useToken"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useToken.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransaction"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useTransaction.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransactionConfirmations"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useTransactionConfirmations.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransactionCount"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useTransactionCount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransactionReceipt"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useTransactionReceipt.js");
;
import(/* webpackMode: "eager", webpackExports: ["useVerifyMessage"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useVerifyMessage.js");
;
import(/* webpackMode: "eager", webpackExports: ["useVerifyTypedData"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useVerifyTypedData.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWaitForTransactionReceipt"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useWaitForTransactionReceipt.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWalletClient"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useWalletClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchAsset"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useWatchAsset.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchBlockNumber"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useWatchBlockNumber.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchBlocks"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useWatchBlocks.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchContractEvent"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useWatchContractEvent.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchPendingTransactions"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useWatchPendingTransactions.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWriteContract"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hooks/useWriteContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["Hydrate"] */ "/vercel/path0/node_modules/wagmi/dist/esm/hydrate.js");

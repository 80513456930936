import {
  ChainId,
  SupportedChain,
  supportedChains,
  supportedChainsById
} from '@/config/networks'
import {
  Select,
  SelectItem,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectTrigger
} from './ui/select'

import { useConnectorChain } from '@/hooks/useConnectorChain'
import { useAccountEffect, useSwitchChain } from 'wagmi'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { useEffect, useState } from 'react'
import * as chains from 'viem/chains'
import type { Chain } from 'viem/chains'

const getNetworkInitials = (name?: string) => {
  const [first, second] = name?.split(' ') ?? []

  return `${first?.[0] ?? ''}${second?.[0] ?? ''}`
}

export const NetworkSelector = () => {
  const { switchChain } = useSwitchChain()
  const connectorChain = useConnectorChain()

  const [internalChain, setInternalChain] = useState<Chain>(
    connectorChain ?? supportedChainsById[chains.base.id]
  )

  useAccountEffect({
    onConnect: ({ chainId }) => {
      if (chainId) {
        setInternalChain(
          Object.values(chains).find((c) => c.id === chainId) ??
            supportedChainsById[chains.base.id]
        )
        switchChain({
          chainId: chainId in supportedChainsById ? chainId : chains.base.id
        })
      }
    },
    onDisconnect: () => {
      setInternalChain(supportedChainsById[chains.base.id])
    }
  })

  useEffect(() => {
    if (connectorChain && connectorChain.id !== internalChain.id) {
      setInternalChain(connectorChain)
    }
  }, [connectorChain, internalChain])

  return (
    <Select
      value={internalChain.name}
      onValueChange={(id) => {
        setInternalChain(supportedChainsById[Number(id) as ChainId])
        switchChain({ chainId: Number(id) as ChainId })
      }}
    >
      <SelectTrigger
        data-testid='switch-network'
        className='border border-gray-500 bg-transparent font-semibold p-2 hover:bg-gray-800'
      >
        <Avatar className='mr-2 w-6 h-6'>
          <AvatarImage
            src={`/network/logo-${internalChain.id}.svg`}
            alt={getNetworkInitials(internalChain.name ?? '')}
          />
          <AvatarFallback className='uppercase text-sm bg-brand-main text-white'>
            {getNetworkInitials(internalChain.name)}
          </AvatarFallback>
        </Avatar>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel className='pl-2'>Supported Networks</SelectLabel>

          {Object.values(supportedChains).map((c) => {
            return (
              <SelectItem value={c.id.toString()} key={c.id}>
                <div className='w-full flex flex-row gap-2 p-2 items-center'>
                  <Avatar className='w-6 h-6'>
                    <AvatarImage
                      src={`/network/logo-${c.id}.svg`}
                      alt={getNetworkInitials(c.name)}
                    />
                    <AvatarFallback className='uppercase'>
                      {getNetworkInitials(c.name)}
                    </AvatarFallback>
                  </Avatar>
                  <p className='text-md font-semibold'>{c.name}</p>
                </div>
              </SelectItem>
            )
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

'use client'

import { FC, useMemo, useState } from 'react'
import { ConnectButton } from '@/components/ConnectButton'
import { NavItem as TNavItem, nav } from '@/constants'
import Link from 'next/link'
import { cn } from '@/utils'
import { usePathname } from 'next/navigation'
import { useTranslation } from '@/app/i18n/client'
import { useWindowScroll } from 'react-use'
import { useCompositeBackground } from '@/hooks/useCompositeBackground'
import Image from 'next/image'
import logo from '../../public/superboring_logo.svg'
import { useDisableScroll } from '@/hooks/useDisableScroll'
import { NetworkSelector } from './NetworkSelector'
import { useAccount } from 'wagmi'
// import { NetworkSelector } from './NetworkSelector'

const isMenuActive = (path: string, currentPathname: string) =>
  path === currentPathname

const NavItem: FC<TNavItem & { activePath: string }> = ({
  path,
  activePath,
  tkey,
  Icon,
  className,
  onClick
}) => {
  const { t, i18n } = useTranslation('menu')

  const isActive = useMemo(
    () =>
      isMenuActive(`/${i18n.language}${path === '/' ? '' : path}`, activePath),
    [path, activePath, i18n.language]
  )

  return (
    <Link
      data-testid={`${tkey}-nav-button`}
      href={path}
      prefetch
      className={cn(
        'flex gap-2 items-center cursor-pointer p-2 text-gray-50 transition-all animate-duration-300 group',
        'hover:text-gray-200 border-brand-main bg-gradient-to-t',
        isActive && 'border-b-2 from-brand-main/20 to-transparent',
        className
      )}
      onClick={onClick}
    >
      <p className={cn(!isActive && 'group-hover:animate-jump')}>{t(tkey)}</p>
    </Link>
  )
}

const HamburguerMenu: FC<{ active: boolean }> = ({ active }) => {
  return (
    <div
      className={cn(
        'flex flex-col gap-[8.5px] w-9 group',
        active && 'group-active'
      )}
    >
      <div className='w-full h-1 bg-white rounded-md transition-all duration-300 delay-200 origin-left group-[.group-active]:rotate-45' />
      <div className='w-full h-1 bg-white rounded-md transition-all duration-500 group-[.group-active]:translate-x-12 group-[.group-active]:opacity-0' />
      <div className='w-full h-1 bg-white rounded-md transition-all duration-300 delay-200 origin-left group-[.group-active]:-rotate-45' />
    </div>
  )
}

const DesktopMenu: FC<{ navList: TNavItem[] }> = ({ navList }) => {
  const { t } = useTranslation('common')

  const activePath = usePathname()
  const { protocol, hostname, port } = window.location

  return (
    <div className='w-screen flex items-center justify-between px-8 gap-4 xxs:max-md:hidden'>
      <div className='flex gap-4'>
        <div className='flex justify-center items-center mr-9'>
          <Link
            href={`${protocol}//${hostname}${port !== '80' ? `:${port}` : ''}`}
          >
            <Image src={logo.src} width={100} height={60} alt='SuperBoring' />
          </Link>
        </div>
        <div className='flex gap-4 items-center'>
          {navList.map((props) => (
            <NavItem key={props.path} {...props} activePath={activePath} />
          ))}
        </div>
      </div>
      <div className='flex gap-2'>
        <NetworkSelector />
        <ConnectButton variant='outline'>{t('connect.default')}</ConnectButton>
      </div>
    </div>
  )
}

const MobileMenu: FC<{ navList: TNavItem[] }> = ({ navList }) => {
  const [isOpen, setOpen] = useState(false)
  const { t } = useTranslation('common')
  useDisableScroll(isOpen)

  const activePath = usePathname()

  return (
    <div
      className={cn(
        'relative w-screen h-20 flex flex-col justify-center px-8 gap-4 md:max-8xl:hidden',
        isOpen && 'bg-black'
      )}
    >
      <div className='flex justify-between items-center'>
        <button type='button' onClick={() => setOpen((prev) => !prev)}>
          <HamburguerMenu active={isOpen} />
        </button>
        <div className='flex gap-2'>
          <NetworkSelector />
          <ConnectButton variant='outline'>
            {t('connect.default')}
          </ConnectButton>
        </div>
      </div>

      <div
        className={cn(
          'absolute left-0 top-20 w-full bg-black h-0 transition-height duration-500 overflow-hidden',
          isOpen && 'h-screen'
        )}
      >
        <div className='flex flex-col gap-4 p-8'>
          {navList.map((props) => (
            <NavItem
              key={props.path}
              {...props}
              activePath={activePath}
              onClick={() => setOpen(false)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export const Header: FC = () => {
  useCompositeBackground()

  const { y } = useWindowScroll()

  const navList = useMemo(() => Object.values(nav), [])

  return (
    <header
      data-testid='header'
      className={cn(
        'h-20 w-full fixed top-0 left-0 flex backdrop-blur-xs z-50',
        y > 0 && 'backdrop-blur-lg'
      )}
    >
      <DesktopMenu navList={navList} />
      <MobileMenu navList={navList} />
    </header>
  )
}
